import React from 'react'
import logo from './Assets/logo.png'
import './style.css'

const Right = () => {
  return (
    <div className='right'>
      <img src={logo} alt="" />
      <h1>LUDO GAME REAL CASH!</h1>
    </div>
  )
}

export default Right
