import React, { useEffect } from 'react'
import { transactionHistory } from '../store/reducer/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../dashboard/Pages/FormateDate';

const Transaction = () => {
  const dispatch = useDispatch()
  const {transactionHistoryData} = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(transactionHistory())
  }, [])
  return (
    <div className=" flex mt-[60px] pt-2 justify-center">
    <div className="w-full mx-auto bg-white shadow-md rounded-lg">
      {Array.isArray(transactionHistoryData) && transactionHistoryData.map((transaction, index) => (
        <div key={index} className="flex justify-between items-center border-b p-2">
          <div className="text-center">
            <div className="text-gray-600 font-semibold text-[0.8rem]">{formatDate(transaction.createdAt)}</div>
            {/* <div className="text-gray-400 text-[0.8rem]">{transaction.time}</div> */}
          </div>
          <div className="flex-1 mx-4">
            <div className="text-black text-[0.8rem] font-semibold">{transaction.type}</div>
            <div className="text-gray-500 text-[0.6rem]">Order ID: {transaction.orderid}</div>
          </div>
          <div className="" >
            <span className={`text-[gray] text-[0.8rem] ${transaction.status==1?"text-green-500":"text-red-500"}`}>{transaction.status==1?"+":"-"}₹{transaction.amount}</span>
            <p className="text-[gray] text-[0.8rem] text-center">Balance</p>
          </div>
        </div>
      ))}
    </div>
  </div>
  )
}

export default Transaction
